import Cookies from 'js-cookie'

const getSiteCookies = () => {
  const cartToken = Cookies.get('session_cart_token')
  const customerId = Cookies.get('session_customer_id')
  const userEmail = Cookies.get('session_email')
  const userId = Cookies.get('session_hp')
  const isGiftOrder = Cookies.get('session_gift_order')
  return {
    ...(cartToken && { cartToken }),
    ...(customerId && { customerId }),
    ...(userEmail && { userEmail }),
    ...(userId && { userId }),
    ...(isGiftOrder && { isGiftOrder })
  }
}

export default getSiteCookies
