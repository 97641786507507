import { createContext, useContext, useState } from 'react'
import delay from 'lodash/delay'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Feedback from '@tofu/apps/shop/components/common/feedback'

const FeedbackContext = createContext({
  add: () => {}
})

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 15px;
  z-index: 13;
  max-width: calc(100% - 30px);
`

const FeedbackProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false)
  const [text, setText] = useState(null)
  const [type, setType] = useState('error')

  const add = (feedbackType, message) => {
    setToggle(true)
    setText(message)
    setType(feedbackType)

    delay(() => setToggle(false), 4000)
  }

  return (
    <FeedbackContext.Provider
      value={{
        add: (feedbackType, message) => add(feedbackType, message)
      }}
    >
      {children}
      <Wrapper>
        {toggle && (
          <Feedback remove={() => setToggle(false)} type={type}>
            {text}
          </Feedback>
        )}
      </Wrapper>
    </FeedbackContext.Provider>
  )
}

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired
}
const useFeedback = () => {
  const feedback = useContext(FeedbackContext)
  if (!feedback)
    throw new Error(
      'useFeedback must be used within a FeedbackProvider component'
    )
  return feedback
}

export { FeedbackContext, FeedbackProvider, useFeedback }
