import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { trackPageView } from '@tofu/shop/utils/rudderstack'

import { TReferrerOptions } from './use-rudderstack-page-view.types'

export const useRudderstackPageView = () => {
  const { events } = useRouter()
  const [prevUrl, setPrevUrl] = useState('')

  useEffect(() => {
    const referrerOptions: TReferrerOptions = {}

    if (prevUrl) {
      referrerOptions.referrer = prevUrl
      referrerOptions.referring_domain = window.location.origin
    }

    const track = (path: string) => {
      const url = window.location.href

      const options = {
        ...referrerOptions,
        url,
        path,
        title: document.title
      }

      trackPageView(options)
      setPrevUrl(url)
    }

    events.on('routeChangeComplete', track)
    return () => {
      events.off('routeChangeComplete', track)
    }
  }, [prevUrl, events])
}
