import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

/**
 * Icons encuded using
 * https://yoksel.github.io/url-encoder/
 *
 * Maybe look at using a library like Mini SVG data: URI to encode the SVG instead
 * https://www.npmjs.com/package/mini-svg-data-uri
 */

const tickIcon = `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z' fill='black'/%3E%3C/svg%3E%0A")`

const getInfoIcon = (fill = 'black') =>
  `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' focusable='false' class='chakra-icon css-fc89v0'%3E%3Cg fill='${fill}' stroke='${fill}' stroke-linecap='square' stroke-width='2'%3E%3Ccircle cx='12' cy='12' fill='none' r='11' stroke='${fill}'%3E%3C/circle%3E%3Cline fill='none' x1='11.959' x2='11.959' y1='11' y2='17'%3E%3C/line%3E%3Ccircle cx='11.959' cy='7' r='1' fill='${fill}' stroke='none'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E")`

const iconMap: Record<string, string> = {
  success: tickIcon,
  info: getInfoIcon(),
  warning: getInfoIcon(),
  error: getInfoIcon('white')
}

export const Alert: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    container: {
      borderRadius: theme.radii['md']
    },
    description: {
      fontSize: theme['fontSizes']['sm']
    }
  }),

  variants: {
    solid: ({ theme, colorScheme, status }: StyleFunctionProps) => ({
      container: {
        bg: theme['colors'][status || colorScheme]?.main,
        color: theme['colors'][status || colorScheme]?.contrastText
      },
      icon: {
        minWidth: '24px',
        display: 'none',
        svg: {
          display: 'none !important'
        },
        '&::after': {
          content: '""',
          width: '100%',
          color: 'currentColor',
          backgroundImage: iconMap[status] || 'none',
          backgroundRepeat: 'no-repeat'
        }
      }
    })
  },
  defaultProps: {
    variant: 'solid'
  }
}
