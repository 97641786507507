import { useEffect } from 'react'
import isString from 'lodash/fp/isString'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

import { TAffiliateTracking } from './affiliate-tracking.types'

/**
 * Should really be a hook (useAffiliateTracking)
 *
 * However, we need to run in in _app.js in order for it not to run each page view
 * _app only supports components until we upgrate to Next13
 *
 */
export const AffiliateTracking: TAffiliateTracking = () => {
  const { query } = useRouter()

  const clickId = query?.['clickid']
  const irclickId = query?.['irclickid']

  useEffect(() => {
    if (isString(irclickId)) {
      irclickId &&
        Cookies.set('irclickid', irclickId, {
          domain: '.allplants.com',
          expires: 30
        })
    }

    // adding session cases loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [irclickId])

  useEffect(() => {
    if (isString(clickId)) {
      clickId &&
        Cookies.set('clickid', clickId, {
          domain: '.allplants.com',
          expires: 30
        })
    }

    // adding session cases loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickId])

  return null
}
