import Cookies from 'js-cookie'
import { createContext, useContext, ReactNode, useMemo } from 'react'

const StatsigTestContext = createContext({
  statsig: {}
})
export const useStatsigTestContext = () => useContext(StatsigTestContext)

export const StatsigTestProvider = ({ children }: { children: ReactNode }) => {
  const statsig = useMemo(() => Cookies.get('statsig'), [])

  return (
    <StatsigTestContext.Provider
      value={{ statsig: statsig && JSON.parse(statsig) }}
    >
      {children}
    </StatsigTestContext.Provider>
  )
}
