import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FocusTrap from 'focus-trap-react'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import Icon from '@tofu/apps/shop/components/common/icon'
import close from '@tofu/apps/shop/icons/close'
import { color, fade } from '@tofu/apps/shop/styles/variables'

const Backdrop = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${color.overlay};
  ${({ active }) =>
    active
      ? `
  z-index: 999999999; // Always appear above basket
  max-height: 100vh;
`
      : `
  display: none;
  max-height: 0;
  `};
`

const Container = styled(Flex)`
  max-width: 300px;
  animation: ${fade} 300ms linear;

  @media (min-width: 360px) {
    max-width: 340px;
  }
  @media (min-width: 380px) {
    max-width: 360px;
  }
  @media (min-width: 400px) {
    max-width: 380px;
  }
  @media (min-width: 480px) {
    max-width: 460px;
  }
  @media (min-width: 640px) {
    max-width: 500px;
  }
  @media (min-width: 992px) {
    max-width: 980px;
  }
`

const Close = styled.button`
  border: none;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: ${color.allplantsYellow};
  cursor: pointer;
  text-align: center;
  z-index: 12;
  padding: 2px 0 0 2px;
  -webkit-appearance: none;
`

const Modal = ({ active, children, handleClose, isDismissable }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleClose()
      }
    }

    const handleKeyPress = ({ code }) => code === 'Escape' && handleClose()

    if (isDismissable && active) {
      // Bind the event listener
      document.addEventListener('keyup', handleKeyPress)
      document.addEventListener('mouseup', handleClickOutside)
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keyup', handleKeyPress)
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [active, handleClose, isDismissable])

  return (
    active && (
      <Backdrop
        active={active}
        alignItems='center'
        data-testid='backdrop'
        justifyContent='center'
      >
        <FocusTrap>
          <Container
            bg={color.white}
            p={4}
            role='dialog'
            position='relative'
            tabIndex='0'
            zIndex={10}
          >
            {isDismissable && (
              <Close aria-label='close' onClick={handleClose}>
                <Icon fill={color.black} name={close} width={16} />
              </Close>
            )}
            <Box
              ref={wrapperRef}
              maxHeight='80vh'
              overflowX='hidden'
              overflowY='scroll'
            >
              {children}
            </Box>
          </Container>
        </FocusTrap>
      </Backdrop>
    )
  )
}

Modal.defaultProps = {
  children: null,
  isDismissable: true
}

Modal.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  isDismissable: PropTypes.bool
}

export default Modal
