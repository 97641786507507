// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  TNormalisedProduct,
  TNormalisedProductVariant
} from '@tofu/shared/utils/products-normaliser'
import { getStatsigOptionsForRudderstackEvents } from '@tofu/shared/utils/statsig'
import { environment } from '@tofu/shared/constants/environments'
import { TCartItems } from '@tofu/shared/types/cart'

import {
  TCartViewedEvent,
  TPageViewOptions,
  TProductAddedEvent,
  TProductClickedEvent,
  TProductData,
  TReviewOrderClickedEvent,
  TWindowWithRudderstack,
  TWithRudderstack
} from './rudderstack.types'

const windowHasRudderstack = (
  providedWindow: Window
): providedWindow is TWindowWithRudderstack =>
  typeof providedWindow !== 'undefined' &&
  typeof (providedWindow as TWindowWithRudderstack).rudderanalytics !==
    'undefined'

const withRudderstack: TWithRudderstack = (cb) => {
  if (windowHasRudderstack(window)) {
    cb(window.rudderanalytics)
  }
}

const trackWithRudderstack = (...args: unknown[]): void =>
  withRudderstack(({ track }) => track(...args))

const getProductData = (
  product: TNormalisedProduct,
  variant: TNormalisedProductVariant
): TProductData => ({
  product_id: product.id,
  category: product.product_type,
  image_url: product.image_url,
  name: product.title,
  sku: variant.sku_code,
  variant: variant.title,
  price: variant.price,
  currency: 'GBP',
  brand: 'allplants',
  url: `https://${environment.shop_base_url}/products/${product.handle}`
})

const statsigOptions = getStatsigOptionsForRudderstackEvents()

export const trackPageView = (options: TPageViewOptions): void => {
  withRudderstack(({ page }) =>
    page(null, null, { ...statsigOptions, ...options })
  )
}

export const trackProductClicked = (
  product: TNormalisedProduct,
  variant: TNormalisedProductVariant | undefined
) => {
  const defaultVariant = product.variants?.DOUBLE_PORTION
  const variantData = variant || defaultVariant

  if (variantData) {
    const data: TProductClickedEvent = {
      ...getProductData(product, variantData),
      ...statsigOptions
    }
    trackWithRudderstack('Product Clicked', data)
  }
}

export const trackProductAdded = (
  product: TNormalisedProduct,
  variant: TNormalisedProductVariant,
  cartId: number,
  quantity: number
) => {
  const data: TProductAddedEvent = {
    ...getProductData(product, variant),
    ...statsigOptions,
    quantity,
    cart_id: cartId
  }

  trackWithRudderstack('Product Added', data)
}

export const trackProductRemoved = (
  product: TNormalisedProduct,
  variant: TNormalisedProductVariant,
  cartId: number,
  quantity: number
) => {
  const data = {
    ...getProductData(product, variant),
    ...statsigOptions,
    quantity,
    cart_id: cartId
  }

  trackWithRudderstack('Product Removed', data)
}

export const trackCartViewed = (
  products: {
    product: TNormalisedProduct
    variant: TNormalisedProductVariant
    quantity: number
  }[],
  cartId: number
) => {
  const data: TCartViewedEvent = {
    ...statsigOptions,
    cart_id: cartId,
    products: products.map(({ product, quantity, variant }) => ({
      ...getProductData(product, variant),
      quantity
    }))
  }

  trackWithRudderstack('Cart Viewed', data)
}

export const trackReviewOrderClicked = (
  products: TCartItems,
  cartId: number,
  targetUrl: `/${string}`
) => {
  const data: TReviewOrderClickedEvent = {
    ...statsigOptions,
    cart_id: cartId,
    products: products.map(({ product_id }) => ({ product_id })),
    review_order_click_link: targetUrl
  }

  trackWithRudderstack('Review Order Clicked', data)
}
