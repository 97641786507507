import round from 'lodash/round'

const standardFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2
})

const withoutZeroPenceFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0
})

export const formatPrice = (price: number) => {
  return standardFormatter.format(price)
}

export const formatPriceWithoutZeros = (price: number) => {
  const hasPence = price % 1 !== 0

  return hasPence
    ? standardFormatter.format(price)
    : withoutZeroPenceFormatter.format(price)
}

export const penceToPounds = (pence: number) => pence / 100

export const formatPriceToFixed = (total: number) => {
  const formatted = penceToPounds(total)
  return formatted.toFixed(2)
}

/**
 * It's important to divide the price by 100 before rounding otherwise it's not rounded correctly
 *
 * Also, for some reason the lodash functional method for round is fucked. no idea why
 * console.log(round(29.965, 2)); // 29.97
 * console.log(round(2, 29.965)); // 2
 *
 */
export const formatAndRoundPriceToFixed = (totalInPence: number): string =>
  round(totalInPence / 100, 2).toFixed(2)
