/* istanbul ignore file */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import usePrevious from '@tofu/apps/shop/hooks/previous'
import { useCart } from '@tofu/shop/hooks/use-cart'
import { TCart } from '@tofu/shared/types/cart'

import { useBasketTracking } from './global-tracking.hooks'

/**
 * For anything we need to track globally, we can add it here.
 */
export const GlobalTracking = () => {
  const { data: cart } = useCart()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const previousCart: TCart = usePrevious(cart)

  useBasketTracking({ cart, previousCart })

  return null
}
