import { css, keyframes } from 'styled-components'

const sizes = {
  xxs: 4,
  xs: 8,
  sm: 14,
  ms: 16,
  base: 18,
  md: 22,
  lg: 26,
  xl: 30,
  xxl: 34,
  xxxl: 48,
  xxxxl: 72
}

const breakpoints = {
  xlDesktop: 1600,
  largeDesktop: 1200,
  desktop: 992,
  tablet: 767,
  phablet: 480,
  phone: 320
}

const color = {
  // Brand colours T3 19
  allplantsYellow: '#FDD902',
  aubergine: '#5e4563',
  blueberry: '#092A50',
  kale: '#05574A',
  mushroom: '#E8E3D9',
  pumpkin: '#D74C10',
  strawbMilkshake: '#EBBAB2',
  veganuaryYellow: '#F6C94A',
  veganuaryPumpkin: '#FC6818',
  mint: '#B1D0CA',
  chickpea: '#F8E8C4',

  // Alerts
  warning: '#FF9719',
  warningLight: '#FFEAD1',

  // Other
  page: '#ffffff',
  overlay: 'rgba(0,0,0,0.6)',
  backgroundGrey: '#F3F3F2',
  border: '#cbcccd',
  borderLight: '#e5e5e5',
  grey: '#666666',
  red: '#f94839',
  redComplementary: '#fee9e7',
  lightGrey: '#CFD0CB',
  lighterGrey: '#F5F5F5',
  lightMint: '#D9E6E2',
  darkGrey: '#666666',
  lighterGreen: '#99cc99',
  paleYellow: '#fef8e5',
  black: '#000000',
  white: '#FFFFFF',
  blue: '#4372A7',
  lightBlue: '#BDD6F2',
  button: {
    outline: '#5e9ed6'
  },
  rachelAmaDarkBlue: '#25164B'
}

const themes = {
  allplantsYellow: {
    bgColor: color.allplantsYellow,
    title: color.black,
    text: color.black
  },
  aubergine: {
    bgColor: color.aubergine,
    title: color.mushroom,
    text: color.white,
    background: 'Aubergine-Mushroom',
    backgroundSize: {
      mobile: '0, 40%',
      desktop: '0, contain'
    },
    backgroundPosition: {
      mobile: 'left top, right bottom',
      desktop: 'left top, right 10%'
    }
  },
  blueberry: {
    bgColor: color.blueberry,
    title: color.mushroom,
    text: color.white,
    background: 'Blueberry-Mushroom',
    backgroundSize: {
      mobile: '50%, 30%',
      desktop: '18%, 12%'
    },
    backgroundPosition: {
      mobile: 'left bottom, right 10%',
      desktop: 'left bottom, right center'
    }
  },
  kale: {
    bgColor: color.kale,
    title: color.mushroom,
    text: color.white,
    background: 'Kale-Strawb',
    backgroundSize: {
      mobile: '6%',
      desktop: '3%'
    },
    backgroundPosition: {
      mobile: '5% 35%, 95% 35%',
      desktop: '5% 35%, 95% 35%'
    }
  },
  strawbMilkshake: {
    bgColor: color.strawbMilkshake,
    title: color.blueberry,
    text: color.black,
    background: 'Blueberry-Mushroom',
    backgroundSize: {
      mobile: '50%, 30%',
      desktop: '18%, 12%'
    },
    backgroundPosition: {
      mobile: 'left bottom, right 10%',
      desktop: 'left bottom, right center'
    }
  },
  monochrome: {
    title: color.black,
    text: color.black
  },
  inverted: {
    title: color.white,
    text: color.white
  },
  disabled: {
    bgColor: color.lighterGrey
  },
  pumpkin: {
    title: color.pumpkin,
    text: color.black
  },
  blackAlpha: {
    bgColor: color.black,
    title: color.white,
    text: color.white
  }
}

const variants = {
  sm: {
    minWidth: 50,
    fontSize: `${sizes.ms}px`
  },
  md: {
    minWidth: 150,
    fontSize: `${sizes.md}px`
  }
}

const easing = {
  hover: 'all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s',
  default: '0.3s ease-in 0s'
}

const transitions = {
  offset: '100px',
  delay: 300
}

const font = {
  family: {
    body: `stolzl, sans-serif`,
    heading: `recent-grotesk, sans-serif`
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700
  },
  lineheights: {
    body: 1.5,
    heading: 1.1,
    blogPost: 1.6
  }
}

const buttons = {
  width: {
    lg: '260px'
  },
  border: `2px solid ${color.black}`,
  buttonText: `display: inline-block;
    font-weight: ${font.weight.medium};
    color: currentColor;
    &:after {
      content: '';
      display: block;
      position: relative;
      top: -4px;
      height: 1px;
      width: 100%;
      border-bottom: 2px solid currentColor;
    }`
}

const animation = {
  cardHover: css`
    cursor: pointer;
    transition: all 0.2s ease-out;
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    :hover {
      transform: scale(1.02);
      box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.1);
    }
  `
}

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export {
  animation,
  breakpoints,
  buttons,
  color,
  easing,
  fade,
  font,
  sizes,
  themes,
  transitions,
  variants
}
