/* eslint-disable @typescript-eslint/ban-ts-comment, unicorn/prefer-object-from-entries */
import { AxiosInstance } from 'axios'

import { TShopConstants, TShopConstantsApi } from './shop-constants.types'

const transform = (obj: TShopConstantsApi): TShopConstants => {
  return {
    deliveryCharge: obj.delivery_charge_pence / 100,
    deliveryChargeThreshold: obj.delivery_charge_threshold / 100,
    minimumOrderValue: obj.minimum_order_value / 100
  }
}

export const getShopConstants = async (
  client: AxiosInstance
): Promise<TShopConstants> => {
  const { data } = await client('/constants')

  return transform(data)
}
