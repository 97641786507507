/* istanbul ignore file */
import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { useConstants } from '@tofu/shared/providers/constants-provider'
import { TCart } from '@tofu/shared/types/cart'
import {
  getShopToastMessage,
  getAccountToastMessage,
  startsWithShop
} from './use-basket-toast.utils'

export const useBasketToast = () => {
  const toast = useToast()
  const router = useRouter()
  const constants = useConstants()
  const isShop = startsWithShop(router?.pathname)

  const showToast = (cart: TCart) => {
    toast.closeAll()
    toast({
      duration: 3000,
      status: 'success',
      title: 'Added to your basket',
      description: isShop
        ? getShopToastMessage(cart, constants)
        : getAccountToastMessage(cart, constants)
    })
  }

  return {
    showToast
  }
}
