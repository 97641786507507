/* istanbul ignore file */
import { useRouter } from 'next/router'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { getCart } from '@tofu/shared/data-access/cart'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useSession } from '@tofu/apps/shop/providers/session'

import { isInvalidResponse, startsWithShop } from './use-cart.utils'

// UseMutationOptions dosen't seem to be working here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCart = (options?: any) => {
  const router = useRouter()
  const queryClient = useQueryClient()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { data: sessionData, removeCookies } = useSession()
  const isShop = startsWithShop(router?.pathname)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { cartToken } = sessionData || {}

  return useQuery(['getCart', cartToken], () => getCart(cartToken), {
    enabled: !!cartToken,
    retry: 1,
    onError: async (error: Error) => {
      if (isInvalidResponse(error?.message)) {
        removeCookies('session_cart_token')
        await queryClient.setQueryData(['getCart'], {})
      }
    },
    onSuccess: async (data) => {
      await queryClient.setQueryData(['getCart', cartToken], data)
      if (
        data?.status === 'COMPLETED' ||
        (isShop && data?.origin === 'account')
      ) {
        removeCookies('session_cart_token')
        await queryClient.setQueryData(['getCart'], {})
      }
    },
    ...options
  })
}
