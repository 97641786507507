import * as prismic from '@prismicio/client'
import * as prismicNext from '@prismicio/next'

import { environment } from '@tofu/shared/constants/environments'

export function createClient({
  previewData,
  req,
  ...config
}: prismicNext.CreateClientConfig = {}) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const client = prismic.createClient(environment.prismic_repo, config)

  prismicNext.enableAutoPreviews({ client, previewData, req })

  return client
}

export const client = createClient()
