import { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { logger } from '@tofu/shared/utils/sentry'
import getSiteCookies from '../utils/cookies'
import { isDevEnv } from '../utils/helpers'

const SessionContext = createContext({
  data: null,
  setCookies: () => {}
})

const defaultOptions = {
  ...(!isDevEnv && { domain: '.allplants.com' }),
  expires: 30,
  path: '/'
}

const defaultPath = {
  ...(!isDevEnv && { domain: '.allplants.com' }),
  path: '/'
}

const SessionProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [session, setSession] = useState(() => getSiteCookies())

  useEffect(() => {
    const isLoggedIn = !isEmpty(session.userId)
    setIsLoggedIn(isLoggedIn)
  }, [session.userId])

  useEffect(() => {
    if (navigator && navigator.cookieEnabled) {
      const cookies = getSiteCookies()
      setSession(cookies) // Update session on the frontend
    }
  }, [])

  const handleRemoveCookies = (name, options = defaultPath) => {
    Cookies.remove(name, options)
    // Account for cookies which may have been set with no domain
    Cookies.remove(name)
    const cookies = getSiteCookies()
    setSession(cookies) // Reset session state again after we've added a new cookie
  }

  const handleSetCookies = (name, value, options = defaultOptions) => {
    Cookies.set(name, value, options)
    const cookies = getSiteCookies()
    setSession(cookies) // Reset session state again after we've added a new cookie
  }

  return (
    <SessionContext.Provider
      value={{
        data: session,
        isLoggedIn,
        removeCookies: (name) => handleRemoveCookies(name),
        setCookies: (name, value, options) =>
          handleSetCookies(name, value, options)
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

SessionProvider.defaultProps = {
  children: PropTypes.any
}

SessionProvider.propTypes = {
  children: PropTypes.element
}

function useSession() {
  const session = useContext(SessionContext)
  if (!session) logger('Component should be within a session provider')
  return session
}

export { SessionProvider, useSession }
