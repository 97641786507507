import startsWith from 'lodash/fp/startsWith'

// eslint-disable-next-line unicorn/prefer-set-has
const invalidResponses = [
  'Invalid Cart token',
  'No Cart with token',
  '403 Forbidden'
]

export const isInvalidResponse = (message: string) =>
  invalidResponses.some((response) => message.includes(response))

export const startsWithShop = startsWith('/shop')
