/* eslint-disable no-nested-ternary */
import { formatPriceToFixed } from '@tofu/shared/utils/format-currency'
import { TCart } from '@tofu/shared/types/cart'
import { TWindowWithDataLayer, TWithDataLayer } from './analytics.types'
/**
 * This library sends events through google tag manager
 * @method Send methods create a new event for any trigger
 * @method Track methods are useful for analytics and will be
 * automatically passed on to the analytics platforms (e.g. Amplitude)
 * with the correct name and data
 */

const windowHasDataLayer = (
  providedWindow: Window
): providedWindow is TWindowWithDataLayer =>
  typeof providedWindow !== 'undefined' &&
  typeof (providedWindow as TWindowWithDataLayer).dataLayer !== 'undefined'

const withDataLayer: TWithDataLayer = (cb) => {
  if (windowHasDataLayer(window)) {
    cb(window.dataLayer)
  }
}

const addData = (content: Record<string, unknown>): void => {
  withDataLayer((dataLayer) => {
    try {
      dataLayer.push(content)
    } catch (error) {
      throw new Error(`Failed to add data for GTM: ${error}`)
    }
  })
}

const sendEvent = (
  eventName: string,
  content: Record<string, unknown>
): void => {
  withDataLayer((dataLayer) => {
    try {
      const data = {
        ...content,
        event: eventName
      }
      dataLayer.push(data)
    } catch (error) {
      throw new Error(`Failed to send event ${eventName} for GTM: ${error}`)
    }
  })
}

const trackAddToCart = (
  id: number,
  price: number,
  slug: string,
  title: string
): void => {
  const data = {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'GBP',
      add: {
        // 'add' actionFieldObject measures.
        products: [
          {
            //  adding a product to a shopping cart.
            name: title,
            id,
            price: Number(formatPriceToFixed(price)),
            quantity: 1,
            variant: 'SUBSCRIPTION'
          }
        ]
      }
    },
    productHandle: slug,
    productName: title,
    productPrice: Number(formatPriceToFixed(price)),
    productVariantId: id
  }
  addData(data)
}

const trackCartUpdated = (cart: TCart): void => {
  const data = {
    event: 'cartUpdated',
    marketing_event_data: {
      frequency: cart.frequency,
      portionSize: cart.portion_size,
      variantIds: cart.items && cart.items.map((i) => i.id).join(','),
      productIds: cart.items && cart.items.map((i) => i.product_id).join(','),
      totalPrice: cart.total_price,
      totalDiscount: cart.total_discount
    }
  }
  addData(data)
}

const trackEvent = (
  name: string,
  data: unknown,
  permanentData?: unknown
): void => {
  addData({ log_data: undefined }) // reset data
  sendEvent('log', {
    log_name: name,
    log_data: data,
    log_perm_data: permanentData
  })
}

const trackClick = (name: string, data: Record<string, unknown>): void => {
  trackEvent('button.click', {
    ...data,
    name
  })
}

const trackModalShow = (modalName: string): void => {
  trackEvent('modal.show', {
    name: modalName
  })
}

const trackProductView = (
  id: number,
  price: number,
  title: string,
  variant: string
): void => {
  const data = {
    event: 'productView',
    ecommerce: {
      currencyCode: 'GBP', // Local currency is optional.
      impressions: [
        {
          id,
          name: title,
          price: Number(formatPriceToFixed(price)),
          variant
        }
      ]
    },
    productName: title,
    productPrice: Number(formatPriceToFixed(price)),
    productVariantId: id
  }
  addData(data)
}

const trackProductClick = (
  id: number,
  price: number,
  title: string,
  variant: string
): void => {
  const data = {
    event: 'productClick',
    ecommerce: {
      click: {
        products: [
          {
            name: title,
            id,
            price: Number(formatPriceToFixed(price)),
            variant
          }
        ]
      }
    },
    productName: title,
    productPrice: Number(formatPriceToFixed(price)),
    productVariantId: id
  }
  addData(data)
}

const trackRemoveFromCart = (
  id: number,
  price: number,
  quantity: number,
  title: string,
  variant: string
): void => {
  const data = {
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name: title,
            id,
            price: Number(formatPriceToFixed(price)),
            quantity,
            variant
          }
        ]
      }
    }
  }
  addData(data)
}

const setUserData = (data: unknown): void =>
  sendEvent('user.data', {
    user_data: data
  })

export {
  addData,
  trackAddToCart,
  trackCartUpdated,
  trackClick,
  trackEvent,
  trackModalShow,
  trackProductClick,
  trackProductView,
  trackRemoveFromCart,
  setUserData
}
