/* istanbul ignore file */
import startsWith from 'lodash/fp/startsWith'
import { TCart } from '@tofu/shared/types/cart'
import { getSubtotal } from '@tofu/shared/utils/cart'
import { TShopConstants } from '@tofu/shared/data-access/shop-constants'
import { formatPriceWithoutZeros as formatPrice } from '@tofu/shared/utils/format-currency'

export const startsWithShop = startsWith('/shop')

export const getShopToastMessage = (
  cart: TCart,
  constants: TShopConstants
): string => {
  const { deliveryCharge, deliveryChargeThreshold, minimumOrderValue } =
    constants
  const currentOrderValue = getSubtotal({ basket: cart, deliveryCharge })

  if (currentOrderValue < minimumOrderValue) {
    return `Spend ${formatPrice(
      minimumOrderValue - currentOrderValue
    )} to reach ${formatPrice(minimumOrderValue)} and checkout`
  }

  if (currentOrderValue < deliveryChargeThreshold) {
    return `Spend ${formatPrice(
      deliveryChargeThreshold - currentOrderValue
    )} to unlock free delivery`
  }

  return `Free delivery unlocked when you subscribe`
}

export const getAccountToastMessage = (
  cart: TCart,
  constants: TShopConstants
): string => {
  const { deliveryCharge, deliveryChargeThreshold, minimumOrderValue } =
    constants
  const currentOrderValue = getSubtotal({ basket: cart, deliveryCharge })

  if (currentOrderValue < minimumOrderValue) {
    return `Spend ${formatPrice(
      minimumOrderValue - currentOrderValue
    )} to reach ${formatPrice(minimumOrderValue)} and check out`
  }

  if (currentOrderValue < deliveryChargeThreshold) {
    return `Spend ${formatPrice(
      deliveryChargeThreshold - currentOrderValue
    )} to get free delivery`
  }

  return `You've unlocked free delivery`
}
