const IS_BROWSER = typeof window !== 'undefined'

async function initMocks() {
  if (IS_BROWSER) {
    const { mswWorker } = await import('./msw-worker')
    mswWorker.start({ onUnhandledRequest: 'bypass' })
  } else {
    const { mswServer } = await import('./msw-server')
    mswServer.listen()
  }
}

initMocks()

export {}
