import axios, { AxiosInstance } from 'axios'

import { getUuid } from '@tofu/shared/utils/uuid'
import { environment } from '@tofu/shared/constants/environments'
import { setUserData, trackEvent } from '@tofu/shared/utils/analytics'

const uuid = getUuid()

const clientAuth = axios.create({
  baseURL: environment.api_base_url,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  },
  timeout: 30_000,
  withCredentials: true
})

clientAuth.defaults.headers.common['X-CSRF-TOKEN'] = uuid

const client: AxiosInstance = axios.create({
  baseURL: environment.api_base_url,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  },
  timeout: 30_000,
  withCredentials: true
})

client.interceptors.response.use((response) => {
  const scheme = response.headers['x-scheme']
  if (scheme && typeof window !== 'undefined') {
    setUserData({
      scheme
    })
    trackEvent('scheme', {
      scheme
    })
  }

  return response
})

export { clientAuth, client }
