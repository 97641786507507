import { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { useRudderstackPageView } from '@tofu/shared/hooks/use-rudderstack-page-view'

const HistoryContext = createContext()

const HistoryProvider = ({ children }) => {
  const [history, setHistory] = useState()
  const { asPath, events } = useRouter()

  useRudderstackPageView()

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Set history object, and log `from` and `to` paths on every route change.
      setHistory({
        from: asPath,
        to: url
      })
    }

    events.on('routeChangeStart', handleRouteChange)
    return () => {
      events.off('routeChangeStart', handleRouteChange)
    }
  }, [asPath, events])

  return (
    <HistoryContext.Provider value={{ history }}>
      {children}
    </HistoryContext.Provider>
  )
}

HistoryProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { HistoryContext, HistoryProvider }
