import { createContext, useState, useContext, ReactNode } from 'react'
import noop from 'lodash/noop'

const FiltersContext = createContext({
  filteredProducts: {},
  setFilteredProducts: noop
})
export const useFiltersContext = () => useContext(FiltersContext)

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const [filteredProducts, setFilteredProducts] = useState({})

  return (
    <FiltersContext.Provider value={{ filteredProducts, setFilteredProducts }}>
      {children}
    </FiltersContext.Provider>
  )
}
