export const Divider = {
  baseStyle: {
    borderColor: 'grey.200'
  },

  sizes: {
    md: { borderBottomWidth: '1px' },
    lg: { borderBottomWidth: '4px !important' }
  },

  defaultProps: {
    variant: 'solid'
  }
}
