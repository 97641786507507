import { useState } from 'react'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import { TReactQueryProvider } from './react-query-provider.types'

export const ReactQueryProvider: TReactQueryProvider = ({
  children,
  pageProps
}) => {
  /**
   * This ensures that data is not shared between
   * different users and requests, while still only creating the
   * QueryClient once per component lifecycle.
   */
  const [queryClient] = useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
       /* @ts-ignore */}

      <Hydrate state={pageProps.dehydratedState}>{children}</Hydrate>
    </QueryClientProvider>
  )
}
