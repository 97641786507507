const config = {
  account_base_url: process.env.ACCOUNT_BASE_URL,
  api_base_url: process.env.API_BASE_URL,
  checkout_base_url: process.env.CHECKOUT_BASE_URL,
  shop_base_url: process.env.SHOP_BASE_URL,
  environment: process.env.ENVIRONMENT,
  gtm_id: process.env.GTM_ID,
  prismic_repo: process.env.PRISMIC_REPO,
  mentionme_url: process.env.MENTIONME_URL,
  uuid_cookie: 'session_a_uuid'
}

export default config
