import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Badge = {
  baseStyle: {
    padding: '4px 10px' // TODO get this from theme once merged
  },
  variants: {
    solid: ({ theme, colorScheme }: StyleFunctionProps) => ({
      borderRadius: theme.radii['md'],
      fontWeight: theme.fontWeights['medium'], // TODO We might not need this, but will have to wait till we get fonts
      bg: theme['colors'][colorScheme].main,
      color: theme['colors'][colorScheme].contrastText
    })
  },

  defaultProps: {
    variant: 'solid'
  }
}
