export const globalStyles = {
  styles: {
    global: ({ theme }) => ({
      'html, body': {
        fontFamily: theme.fonts.body
      },
      p: {
        lineHeight: 1.5, // import from theme
        fontWeight: 400 // import from theme
      },
      '::selection': {
        backgroundColor: theme.colors.allplantsYellow // todo: colors.yellow[main] not allplantsYellow
      }
    })
  }
}
