import PropTypes from 'prop-types'

const Icon = ({ fill, name, width, ...rest }) => (
  <svg
    height={width}
    role='img'
    viewBox={name.viewBox}
    width={width}
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path d={name.path} fill={fill} />
  </svg>
)

Icon.defaultProps = {
  fill: 'currentColor',
  width: 100
}

Icon.propTypes = {
  fill: PropTypes.string,
  name: PropTypes.shape({
    path: PropTypes.string,
    viewBox: PropTypes.string
  }).isRequired,
  width: PropTypes.number
}

export default Icon
