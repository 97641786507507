import Cookies from 'js-cookie'
import { environment as environments } from '@tofu/shared/constants/environments'
import { TGetStatsigOptionsForRudderstackEvents } from './statsig.types'

const { environment, uuid_cookie } = environments

// Middleware will automatically set a cookie for the user if they visit a page
const sessionUUID = Cookies.get(uuid_cookie) || ''
export const getStatsigOptionsForRudderstackEvents: TGetStatsigOptionsForRudderstackEvents =
  () => ({
    statsigCustomIDs: ['Session UUID', sessionUUID],
    statsigEnvironment: { tier: environment }
  })
