import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Heading: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    fontWeight: theme.fontWeights.ultra,
    fontFamily: theme.fonts.heading,
    textTransform: 'uppercase'
  })
}
