import { clientAuth } from '@tofu/shared/utils/axios-client'

import { TUpdateCart } from './cart.types'
import { getMessageFromResponse } from './cart.utils'

export const updateCart: TUpdateCart = async (token, data) => {
  try {
    const response = await clientAuth.put(`carts/${token}`, data)

    return response?.data?.data?.[0]
  } catch (error) {
    throw error?.response?.data?.errors?.[0]
  }
}

export const getCart = async (token: string) => {
  try {
    const response = await clientAuth.get(`carts/${token}`)
    return response?.data?.data?.[0]
  } catch (error) {
    const msg = getMessageFromResponse(error)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw new Error(msg)
  }
}
