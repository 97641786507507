// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { client } from '@tofu/shop/data-access/prismic'

import {
  // TODO: remove this?
  // TGlobalShopSettings,
  TGetGlobalSettingsShop
} from './get-global-shop-settings.types'

export const getGlobalShopSettings =
  async (): Promise<TGetGlobalSettingsShop> => {
    const response = await client.getSingle('global_settings_shop', {})

    return {
      default_promo_banner: {
        text: response?.data?.default_promo_banner
      }
    }
  }
