import sortBy from 'lodash/sortBy'
import constants from '../constants/env'

const alphabetise = (arr, param) => sortBy(arr, [param])

const endsWith = (str, suffix) =>
  str.includes(suffix, str.length - suffix.length)

const isDevEnv = constants.environment === 'development'

const isProd = constants.environment === 'production'

const pickRandom = (arr) =>
  arr?.length > 0 ? arr[Math.floor(Math.random() * arr.length)] : undefined

const splitByComma = (string) => (string ? string.split(',') : [])

export { alphabetise, endsWith, isDevEnv, isProd, pickRandom, splitByComma }
