import { Dict } from '@chakra-ui/utils'
import type { ComponentStyleConfig } from '@chakra-ui/theme'

import type {
  StyleFunctionProps,
  SystemStyleObject
} from '@chakra-ui/theme-tools'

import { TColorScheme } from '../foundations/colors'

export type StyleFunction = {
  [key: string]: SystemStyleObject
}

const getSolidPsudoState = (
  theme: Dict,
  colorScheme: TColorScheme
): StyleFunction => {
  const states: Record<string, StyleFunction> = {
    blackAlpha: {
      ':hover': {
        bg: theme['colors'].whiteAlpha.main,
        color: theme['colors'].whiteAlpha.contrastText,
        boxShadow: `inset 0px 0px 0px 2px ${theme['colors'].black}`,
        ':active': {
          boxShadow: `inset ${theme.shadows.outline}`
        }
      }
    }
  }

  return states[colorScheme]
}

export const Button: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    borderRadius: 'none',
    fontWeight: theme['fontWeights'].bold
  }),
  variants: {
    solid: ({ theme, colorScheme }: StyleFunctionProps) => ({
      textTransform: 'uppercase',
      transition: 'none',
      bg: theme['colors'][colorScheme].main,
      color: theme['colors'][colorScheme].contrastText,

      ':hover': {
        bg: theme['colors'].blackAlpha.main,
        color: theme['colors'].blackAlpha.contrastText
      },

      ':active': {
        boxShadow: `inset ${theme.shadows.outline}`
      },

      // hover, active, focus, etc...
      ...getSolidPsudoState(theme, colorScheme),

      ':disabled': {
        opacity: 1,
        bg: theme['colors'].grey[200],
        color: theme['colors'].grey[600],
        boxShadow: `none`
      }
    }),
    outline: ({ theme }: StyleFunctionProps) => ({
      bg: 'white',
      textTransform: 'uppercase',
      border: 'none',
      boxShadow: `inset 0px 0px 0px 2px ${theme['colors'].black}`,
      color: 'black',
      transition: 'none',
      ':hover': {
        bg: theme['colors'].blackAlpha.main,
        color: theme['colors'].blackAlpha.contrastText
      },
      ':active': {
        boxShadow: `inset ${theme.shadows.outline}`
      },
      ':disabled': {
        opacity: 1,
        color: theme['colors'].grey[300],
        boxShadow: `inset 0px 0px 0px 2px ${theme['colors'].grey[300]}`
      }
    }),
    link: ({ theme }: StyleFunctionProps) => ({
      color: theme['colors'].black,
      fontWeight: theme['fontWeights'].medium,
      textDecoration: 'underline',
      ':hover': {
        color: theme['colors'].black
      }
    }),
    /**
     * Invisible
     * Used for the ProductCard Image Wrappers
     */
    invisible: {
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      outline: '0px',
      border: '0px',
      margin: '0px',
      borderRadius: '0px',
      padding: '0px',
      cursor: 'pointer',
      height: 'auto',
      userSelect: 'none',
      appearance: 'none',
      textDecoration: 'none',
      color: 'inherit',
      display: 'block',
      textAlign: 'inherit',
      width: '100%',
      position: 'relative'
    }
  },

  defaultProps: {
    variant: 'text'
  }
}
