import Cookies from 'js-cookie'
import { getCode, getMsg } from '@tofu/apps/shop/utils/error'
import { clientAuth } from '@tofu/shared/utils/axios-client'

const getUtm = () => {
  const utmmedium = Cookies.get('utm_medium')
  const utmterm = Cookies.get('utm_term')
  const utmsource = Cookies.get('utm_source')
  const utmname = Cookies.get('utm_name')
  const utmcampaign = Cookies.get('utm_campaign')
  const utmcontent = Cookies.get('utm_content')
  return {
    ...(utmmedium && { utmmedium }),
    ...(utmterm && { utmterm }),
    ...(utmsource && { utmsource }),
    ...(utmname && { utmname }),
    ...(utmcampaign && { utmcampaign }),
    ...(utmcontent && { utmcontent })
  }
}

const addCartItem = async (token, variant_id) => {
  try {
    await clientAuth.post('cart_items', {
      variant_id,
      quantity: 1,
      cart_token: token
    })
    return
  } catch (error) {
    const code = getCode(error)
    throw new Error(`Failed to add item to cart: ${code || error?.message}`)
  }
}

const createCart = async ({ isShop = true, ...rest } = {}) => {
  // Only carts created from Shop should be initialised with this data
  const initShopCart = {
    frequency: 'TWO_WEEKS',
    origin: 'project-honey',
    utm: getUtm()
  }

  try {
    const response = await clientAuth.post('carts', {
      ...(isShop ? initShopCart : {}),
      ...rest
    })
    return (
      response && response.data && response.data.data && response.data.data[0]
    )
  } catch (error) {
    const code = getCode(error)
    throw new Error(`Failed to create cart:  ${code || error?.message}`)
  }
}

const getCart = async (token) => {
  try {
    const response = await clientAuth.get(`carts/${token}`)
    return (
      response && response.data && response.data.data && response.data.data[0]
    )
  } catch (error) {
    const code = getCode(error)
    const msg = getMsg(error)

    throw new Error(`Failed to get cart: ${code || msg || error?.message}`)
  }
}

const removeCartItem = async (id) => {
  try {
    await clientAuth.delete(`cart_items/${id}`)
    return
  } catch (error) {
    const code = getCode(error)
    throw new Error(
      `Failed to remove item from cart: ${code || error?.message}`
    )
  }
}

const updateCart = async (token, data) => {
  try {
    const response = await clientAuth.put(`carts/${token}`, data)
    return response?.data?.data?.[0]
  } catch (error) {
    const code = getCode(error)
    throw new Error(`Failed to update cart: ${code || error?.message}`)
  }
}

const updateCartItem = async (id, quantity) => {
  try {
    const response = await clientAuth.put(`cart_items/${id}`, {
      quantity
    })
    return (
      response && response.data && response.data.data && response.data.data[0]
    )
  } catch (error) {
    const code = getCode(error)
    throw new Error(`Failed to update cart item: ${code || error?.message}`)
  }
}

export {
  addCartItem,
  createCart,
  getCart,
  removeCartItem,
  updateCart,
  updateCartItem
}
