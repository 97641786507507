import get from 'lodash/fp/get'
import includes from 'lodash/fp/includes'
import flow from 'lodash/fp/flow'
import { formatPriceToFixed as formatPrice } from '@tofu/shared/utils/format-currency'

const getCode = (err) => err?.response?.data?.errors?.[0]?.error_code
const getMsg = (err) => err?.response?.data?.errors?.[0].message

const getMessage = get('message')
const includesDiscountNotFound = includes('discount_not_found')
const includesDiscountNewCustomersOnly = includes('discount_new_customers_only')

const getDiscountErrorMsg = (discount) =>
  discount.discount_type === 'PERCENTAGE'
    ? `We have applied a ${discount.amount}% discount`
    : `We have taken £${formatPrice(discount.amount)} off your order`

const getErrorMessage = (error, phrase) => error?.message.includes(phrase)

const isInvalidToken = (error) =>
  getErrorMessage(error, 'Invalid Cart token') ||
  getErrorMessage(error, 'No Cart with token') ||
  getErrorMessage(error, '403 Forbidden')

const isErrorDiscountNotFound = flow(getMessage, includesDiscountNotFound)
const isErrorDiscountForNewCustomersOnly = flow(
  getMessage,
  includesDiscountNewCustomersOnly
)

export {
  isErrorDiscountNotFound,
  isErrorDiscountForNewCustomersOnly,
  getCode,
  getDiscountErrorMsg,
  getMsg,
  isInvalidToken
}
