import { extendTheme } from '@chakra-ui/react'

import { globalStyles } from './styles'
import { colors } from './foundations/colors'
import { fonts } from './foundations/fonts'
import { fontSizes } from './foundations/font-sizes'
import { fontWeights } from './foundations/font-weights'
import { textStyles } from './foundations/text-styles'
import { radii } from './foundations/border-radius'
import { shadows } from './foundations/shadows'
import { sizes } from './foundations/sizes'
import { tokens } from './foundations/tokens'

import { Alert } from './components/alert'
import { Avatar } from './components/avatar'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { Checkbox } from './components/checkbox'
import { Divider } from './components/divider'
import { Drawer } from './components/drawer'
import { Heading } from './components/heading'
import { Modal } from './components/modal'
import { Progress } from './components/progress'
import { Table } from './components/table'

// Default Theme
export default extendTheme({
  globalStyles,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  radii,
  textStyles,
  shadows,
  sizes,
  semanticTokens: { ...tokens },
  components: {
    Alert,
    Avatar,
    Badge,
    Button,
    Checkbox,
    Divider,
    Drawer,
    Heading,
    Modal,
    Progress,
    Table
  },
  styles: {
    global: {
      '.chakra-toast': {
        '.chakra-alert': {
          alignItems: 'center'
        },
        '.chakra-alert__title, .chakra-alert__desc': {
          textAlign: 'center'
        }
      },
      svg: { display: 'inline' }
    }
  }
})

export { ChakraProvider as ThemeProvider } from '@chakra-ui/react'

export * from './foundations/colors' // to get the Colour Types
