import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Table: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    thead: {
      bg: 'black'
    },
    th: {
      textTransform: 'none',
      fontWeight: 'normal',
      fontFamily: theme.fonts.body
    },
    td: {
      ...theme.textStyles['body-xs']
    }
  }),
  variants: {
    striped: {
      th: {
        color: 'white'
      },
      tbody: {
        tr: {
          '&:nth-of-type(odd)': {
            'th, td': {
              borderBottomWidth: '1px',
              borderColor: 'grey.100'
            },
            td: {
              background: 'grey.100'
            }
          }
        }
      }
    },
    simple: {
      th: {
        color: 'white'
      }
    }
  }
}
