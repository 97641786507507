import { createContext, useContext } from 'react'

import { TConstantsProvider } from './constants-provider.types'

export const ConstantsContext = createContext({
  deliveryCharge: 0,
  deliveryChargeThreshold: 0,
  minimumOrderValue: 0
})
export const useConstants = () => useContext(ConstantsContext)

export const ConstantsProvider: TConstantsProvider = ({
  children,
  constants
}) => {
  return (
    <ConstantsContext.Provider value={{ ...constants }}>
      {children}
    </ConstantsContext.Provider>
  )
}
