// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { Colors } from '@chakra-ui/react'

export const baseColors: Colors = {
  white: '#fff',
  black: '#000',

  yellow: {
    50: '#FFFBDB',
    100: '#FFF4AD',
    200: '#FEEC7E',
    300: '#FEE44C',
    400: '#FDD902', // brandYellow, lemon
    500: '#E4C302',
    600: '#B19800',
    700: '#7F6D00',
    800: '#4C4100',
    900: '#1B1600'
  },

  blue: {
    50: '#E4F3FF',
    100: '#BAD7F7',
    200: '#8FBCEF',
    300: '#64A1E9',
    400: '#3C86E3',
    500: '#4157A5', // blackberry
    600: '#1D549D',
    700: '#133C71',
    800: '#092A50', // blueberry
    900: '#000C1B'
  },

  orange: {
    50: '#FFECDF',
    100: '#FDCFB4',
    200: '#F8BC85',
    300: '#FC8A21',
    400: '#F18728', // tangerine
    500: '#D74C10', // pumpkin
    600: '#A83A0B',
    700: '#792907',
    800: '#4A1701',
    900: '#1E0500'
  },

  red: {
    50: '#FFE9E3',
    100: '#FBC4B9', //tomato
    200: '#F29F8D',
    300: '#EB7961',
    400: '#E35535', // error
    500: '#E6371F', // strawberry
    600: '#9E2D14',
    700: '#71200D',
    800: '#461105',
    900: '#1E0300'
  },

  green: {
    50: '#E0FDEF',
    100: '#BBF2D7',
    200: '#94E8BF',
    300: '#6CDEA6', // success
    400: '#44D48D',
    500: '#2BBB74', // cucumber
    600: '#1E9159',
    700: '#12683F',
    800: '#043F25',
    900: '#001708'
  },

  grey: {
    50: '#F5F5F5', // not being used
    100: '#F5F5F5',
    200: '#EDEDED', // ash
    300: '#C7C7C7',
    400: '#999999',
    500: '#8C8C8C',
    600: '#6D6D6D',
    700: '#515151',
    800: '#282626',
    900: '#120B0D'
  },

  rachelAmaDarkBlue: '#25164B',
  melissaHemsleyRed: '#EB4A4F'
}

export const statuses = {
  success: baseColors.green[300],
  info: baseColors.blue[100],
  warning: baseColors.yellow[100],
  error: baseColors.red[400]
}

export const legacyColorSchemes = {
  brandYellow: {
    main: baseColors.yellow[400],
    contrastText: baseColors.black
  },
  tomato: {
    main: baseColors.red[100],
    contrastText: baseColors.black
  },
  pumpkin: {
    main: baseColors.orange[500],
    contrastText: baseColors.black
  },
  kale: {
    main: baseColors.green[500],
    contrastText: baseColors.white
  },
  apple: {
    main: baseColors.green[200],
    contrastText: baseColors.black
  },
  sky: {
    main: baseColors.blue[50],
    dark: baseColors.blue[400],
    contrastText: baseColors.black
  },
  blueberry: {
    main: baseColors.blue[800],
    contrastText: baseColors.white
  }
}

export const newColourSchemes = {
  // previously brandYellow
  lemon: {
    main: baseColors.yellow[400],
    contrastText: baseColors.black
  },

  // previously kale
  cucumber: {
    main: baseColors.green[500],
    contrastText: baseColors.white
  },

  // previously kale
  cucumber: {
    main: baseColors.green[500],
    contrastText: baseColors.white
  },

  strawberry: {
    main: baseColors.red[500],
    contrastText: baseColors.white
  },

  tangerine: {
    main: baseColors.orange[400],
    contrastText: baseColors.black
  },

  blackberry: {
    main: baseColors.blue[500],
    contrastText: baseColors.white
  },

  ash: {
    main: baseColors.grey[200],
    contrastText: baseColors.black
  },

  whiteAlpha: {
    main: baseColors.white,
    contrastText: baseColors.black
  },

  blackAlpha: {
    main: baseColors.black,
    contrastText: baseColors.white
  }
}

export const colorSchemes = {
  /**
   * Older color schemes
   * Slowly phase these out
   */
  ...legacyColorSchemes,

  /**
   * Newer color schemes
   */

  ...newColourSchemes,

  /**
   * Status colors
   * Used For Alerts, Toasts, etc...
   */

  success: {
    main: statuses.success,
    contrastText: baseColors.black
  },
  info: {
    main: statuses.info,
    contrastText: baseColors.black
  },
  error: {
    main: statuses.error,
    contrastText: baseColors.white
  },
  warning: {
    main: statuses.warning,
    contrastText: baseColors.black
  }
}

export const colorSchemeList = Object.keys(colorSchemes)
export type TColorScheme = (typeof colorSchemeList)[number]

export const colors = {
  ...baseColors,
  ...statuses,
  ...colorSchemes
}
