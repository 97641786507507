import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Drawer: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    header: {
      flexBasis: '56px',
      padding: theme.space[4],
      fontWeight: theme['fontWeights'].bold,
      fontSize: theme['fontSizes']['lg'],
      textAlign: 'center',
      marginBottom: theme.space[5]
    }
  })
}
