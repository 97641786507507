import { createContext, useContext, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { getBlogNav, getBySingleType } from '../services/prismic'

const PrismicDataContext = createContext({
  banners: null,
  blogNav: null
})

const PrismicDataProvider = ({ children }) => {
  const [banners, setBanners] = useState()
  const [blogNav, setBlogNav] = useState()

  const fetchBanners = async () => {
    const result = await getBySingleType('global_banners')
    setBanners(result.data)
  }

  const fetchBlogNav = async () => {
    const result = await getBlogNav()
    setBlogNav(result?.data?.main_nav)
  }

  useEffect(() => {
    fetchBanners()
    fetchBlogNav()
  }, [])

  return (
    <PrismicDataContext.Provider value={{ banners, blogNav }}>
      {children}
    </PrismicDataContext.Provider>
  )
}

PrismicDataProvider.defaultProps = {
  children: null
}

PrismicDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const usePrismicData = () => {
  const data = useContext(PrismicDataContext)
  if (!data)
    throw new Error(
      'usePrismicData must be used within a PrismicDataContext component'
    )
  return data
}

export { PrismicDataContext, PrismicDataProvider, usePrismicData }
