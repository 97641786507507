import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Avatar: ComponentStyleConfig = {
  sizes: {
    xl: () => ({
      container: {
        width: '80px',
        height: '80px'
      }
    })
  }
}
