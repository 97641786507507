import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Checkbox: ComponentStyleConfig = {
  baseStyle: ({ theme, colorScheme = 'blueberry' }: StyleFunctionProps) => ({
    control: {
      _checked: {
        borderColor: theme['colors'][colorScheme].main,
        bg: theme['colors'][colorScheme].main
      }
    },
    icon: {
      _checked: {
        bg: theme['colors'][colorScheme].main
      }
    }
  })
}
