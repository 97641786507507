import { useEffect } from 'react'

import { trackCartUpdated, trackEvent } from '@tofu/shared/utils/analytics'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { hasDeliveryCharge } from '@tofu/shared/utils/cart'
import { TUseBasketTracking } from './global-tracking.types'

export const useBasketTracking: TUseBasketTracking = ({
  cart,
  previousCart
}) => {
  const hasMetMinumumOrderValue =
    previousCart?.status !== 'PURCHASABLE' && cart?.status === 'PURCHASABLE'

  const hasMetFreeDelivery =
    cart?.status === 'PURCHASABLE' &&
    hasDeliveryCharge(previousCart?.items) &&
    !hasDeliveryCharge(cart.items)

  useEffect(() => {
    if ((cart?.id && !previousCart?.updated_at) || !cart?.updated_at) {
      return
    }

    const hasCartUpdated = previousCart?.updated_at !== cart?.updated_at

    if (hasCartUpdated) {
      trackCartUpdated(cart)

      // This is greater than or equals to because the item_count includes the delivery item
      // https://app.asana.com/0/1202534130986686/1204650452378328/f
      const hasCartQuantityIncreased =
        cart?.item_count >= previousCart?.item_count

      if (hasCartQuantityIncreased) {
        if (hasMetMinumumOrderValue) {
          trackEvent('minimum_order.met', cart)
        }

        if (hasMetFreeDelivery) {
          trackEvent('free_delivery.met', cart)
        }
      }
    }
  }, [cart, hasMetFreeDelivery, hasMetMinumumOrderValue, previousCart])
}
