import { remCalc } from '@tofu/shared/utils/rem-calc'

export const fontSizes = {
  '1xs': remCalc(10), // caption
  xs: remCalc(12), // body-xs
  sm: remCalc(14), // body-sm
  md: remCalc(16), // body, h6
  lg: remCalc(18), // body-lg
  xl: remCalc(20), // h5
  '1xl': remCalc(24), // body-xl, h4 (mobile)
  '2xl': remCalc(28), // h4
  '3xl': remCalc(40), // h3
  '4xl': remCalc(50),
  '5xl': remCalc(56), // h2
  '6xl': remCalc(80), // h1
  '7xl': remCalc(92), // hero, hero-condensed
  '8xl': remCalc(108) // hero, hero-condensed
}
