import { useDisclosure } from '@chakra-ui/react'
import useLocalStorage from 'use-local-storage'
import { createContext, useContext, useState } from 'react'
import { TSelectedPortionSize } from '@tofu/shared/types/selected-portion-size'
import { TVariantPortionSize } from '@tofu/shared/types/product'

import { TUIContextProps, TUIProvider } from './ui-provider.types'

export const UIProviderContext = createContext<TUIContextProps>({
  isOpenShopMinSpendAlert: true,
  isUpdatingPortonSize: false,
  onCloseShopMinSpendAlert: () => null,
  selectedPortionSize: 'SINGLE_PORTION',
  setSelectedPortionSize: () => null,
  shouldShowPromptMixAndMatch: true,
  setShouldShowPromptMixAndMatch: () => null
})

export const useUIProviderContext = () => useContext(UIProviderContext)

export const UIProvider: TUIProvider = ({ children }) => {
  const [isUpdatingPortonSize, setIsUpdatingPortonSize] = useState(false)

  const { isOpen: isOpenShopMinSpendAlert, onClose: onCloseShopMinSpendAlert } =
    useDisclosure({
      defaultIsOpen: true
    })

  const [selectedPortionSizeState, setSelectedPortionSizeState] =
    useState<TSelectedPortionSize>('SINGLE_PORTION')

  const [shouldShowPromptMixAndMatch, setShouldShowPromptMixAndMatch] =
    useLocalStorage<boolean>('allplants_v1_shouldShowPromptMixAndMatch', true)

  const setSelectedPortionSize = (portionSize: TVariantPortionSize) => {
    setIsUpdatingPortonSize(true)
    setTimeout(() => {
      setIsUpdatingPortonSize(false)
      setSelectedPortionSizeState(portionSize)
    }, 400)
  }

  return (
    <UIProviderContext.Provider
      value={{
        isOpenShopMinSpendAlert,
        isUpdatingPortonSize,
        onCloseShopMinSpendAlert,
        selectedPortionSize: selectedPortionSizeState,
        setSelectedPortionSize,
        shouldShowPromptMixAndMatch,
        setShouldShowPromptMixAndMatch
      }}
    >
      {children}
    </UIProviderContext.Provider>
  )
}
