import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Progress: ComponentStyleConfig = {
  baseStyle: ({ theme, colorScheme }: StyleFunctionProps) => ({
    filledTrack: {
      backgroundColor: theme['colors'][colorScheme].main
    }
  })
}
