import { buttons, color, font, sizes } from './variables'

// This is our global CSS file.
const global = ` 
  * {
    box-sizing: border-box;
  }

  ::selection {
    background-color: ${color.allplantsYellow};
  }
  
  body, html {
    margin: 0;
    font-family: ${font.family.body};
    font-size: ${sizes.ms}px;
    line-height: ${font.lineheights.body};
    font-weight: ${font.weight.regular};
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    background: ${color.white};
  }

  html {
    scroll-behavior: auto;
  }

  body.no-scroll {
    overflow: hidden;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }

  h3 {
    font-size: ${sizes.md}px;
  }

  b, strong{
    font-weight: ${font.weight.bold};
  }

  p {
    margin: ${sizes.sm}px 0;
    a {
      ${buttons.buttonText}
    }
  }

  button {
    cursor: pointer;
    color: ${color.black};
  }

  button:focus{
      outline: none;
      box-shadow: 0 0 0 2px ${color.button.outline};
  }

  .async-hide { 
    opacity: 0 !important 
  }
`

export default global
