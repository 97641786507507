import { createContext, useContext } from 'react'

import {
  TGlobalShopContentProvider,
  TGlobalShopContentProps
} from './global-shop-content-provider.types'

export const GlobalShopContentContext = createContext<TGlobalShopContentProps>({
  defaultPromoBanner: {
    text: []
  }
})
export const useGlobalShopContentContext = () =>
  useContext(GlobalShopContentContext)

export const GlobalShopContentProvider: TGlobalShopContentProvider = ({
  children,
  defaultPromoBanner
}) => {
  return (
    <GlobalShopContentContext.Provider value={{ defaultPromoBanner }}>
      {children}
    </GlobalShopContentContext.Provider>
  )
}
