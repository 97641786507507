import { fonts } from './fonts'
import { fontSizes } from './font-sizes'
import { fontWeights } from './font-weights'
import { lineHeights } from './line-heights'

const { base, none } = lineHeights
const { regular, black, ultra, medium } = fontWeights

const h1FontSizeFallback = [
  fontSizes['3xl'],
  fontSizes['4xl'],
  fontSizes['6xl']
]

// https://utopia.fyi/type/calculator/?c=320,40,1.2,1200,80,1.25,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l
const h1FontSize = `clamp(${h1FontSizeFallback[0]}, calc(1.59rem + 4.55vw), ${h1FontSizeFallback[2]})`

export const textStyles = {
  hero: {
    lineHeight: none,
    fontWeight: medium,
    fontFamily: fonts.heading,
    textTransform: 'uppercase',
    fontSize: ['16vw', '16vw', fontSizes['8xl']]
  },
  'hero-condensed': {
    lineHeight: none,
    fontWeight: black,
    fontFamily: fonts.heading,
    letterSpacing: ['1px', '2px', '2px'],
    fontSize: ['16vw', '16vw', fontSizes['8xl']],
    textTransform: 'uppercase'
  },

  h1: {
    fontSize: h1FontSizeFallback,
    [`@supports (font-size: ${h1FontSize})`]: {
      fontSize: h1FontSize
    },
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: none,
    letterSpacing: '4px',
    textTransform: 'uppercase'
  },

  'h1-condensed': {
    fontSize: h1FontSizeFallback,
    [`@supports (font-size: ${h1FontSize})`]: {
      fontSize: h1FontSize
    },
    fontWeight: black,
    fontFamily: fonts.heading,
    letterSpacing: '4px',
    textTransform: 'uppercase',
    lineHeight: ['calc(100% - 5px)', 'calc(100% - 20px)', 'calc(100% - 30px)']
  },

  h2: {
    fontSize: [fontSizes['3xl'], fontSizes['5xl']],
    lineHeight: none,
    fontWeight: ultra,
    fontFamily: fonts.heading,
    letterSpacing: '0.5px',
    textTransform: 'uppercase'
  },

  'h2-condensed': {
    fontSize: [fontSizes['3xl'], fontSizes['5xl']],
    lineHeight: none,
    fontWeight: black,
    fontFamily: fonts.heading,
    letterSpacing: '-0.5px',
    textTransform: 'uppercase'
  },

  h3: {
    fontSize: [fontSizes['2xl'], fontSizes['3xl']],
    fontWeight: ultra,
    lineHeight: none,
    fontFamily: fonts.heading,
    letterSpacing: '0.5px',
    textTransform: 'uppercase'
  },

  h4: {
    fontSize: [fontSizes['1xl'], fontSizes['2xl']],
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: base,
    textTransform: 'uppercase'
  },

  h5: {
    fontSize: [fontSizes['lg'], fontSizes['xl']],
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: base,
    textTransform: 'uppercase'
  },

  h6: {
    fontSize: fontSizes['md'],
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: base,
    letterSpacing: '.5px', // move to theme
    textTransform: 'uppercase'
  },

  'body-xl': {
    fontSize: [fontSizes['md'], fontSizes['1xl']],
    fontWeight: regular,
    lineHeight: base // 34px
  },

  'body-lg': {
    fontSize: [fontSizes['md'], fontSizes['lg']],
    fontWeight: regular
  },

  /**
   * when using font weight 400 increase the line height to 24px (large)
   */
  body: {
    fontSize: [fontSizes['sm'], fontSizes['md']],
    fontWeight: regular,
    lineHeight: base
  },

  'body-sm': {
    fontSize: fontSizes['sm'],
    fontWeight: regular,
    lineHeight: base
  },

  'body-xs': {
    fontSize: fontSizes['xs'],
    fontWeight: regular,
    lineHeight: base
  },

  caption: {
    fontSize: fontSizes['1xs'],
    fontWeight: regular,
    lineHeight: base
  }
}
