import { colorSchemes, statuses, baseColors } from './colors'

export const tokens = {
  colors: {
    /** Schemes */
    blueberry: colorSchemes.blueberry.main,
    allplantsYellow: colorSchemes.brandYellow.main, // Legacy version of brandYellow
    brandYellow: colorSchemes.brandYellow.main,
    tomato: colorSchemes.tomato.main,
    pumpkin: colorSchemes.pumpkin.main,
    sky: colorSchemes.sky.main,
    kale: colorSchemes.kale.main,
    apple: colorSchemes.apple.main,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cucumber: baseColors.green[500],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tangerine: baseColors.orange[400],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lemon: baseColors.yellow[400],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    strawberry: baseColors.red[500],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    blackberry: baseColors.blue[500],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ash: baseColors.grey[200],

    /** Statuses */
    success: statuses.success,
    info: statuses.info,
    warning: statuses.warning,
    error: statuses.error
  }
}
