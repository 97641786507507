import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Modal: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    header: {
      padding: `${theme.space[5]} ${theme.space[4]} ${theme.space[3]} ${theme.space[4]}`,
      fontWeight: theme['fontWeights'].bold,
      fontSize: theme['fontSizes']['lg'],
      textAlign: 'center'
    },
    body: {
      padding: `0 ${theme.space[4]} ${theme.space[4]} ${theme.space[4]}`
    },
    footer: {
      padding: `${theme.space[4]}`
    }
  })
}
