import * as prismic from '@prismicio/client'
import flatten from 'lodash/flatten'
import { logger } from '@tofu/shared/utils/sentry'
import { client } from '@tofu/shop/data-access/prismic'

const getPageContent = async (content_type, uid, ref = null) => {
  try {
    const page = await client.getByUID(content_type, uid, {
      fetchLinks: [
        'author.name',
        'author.id',
        'author.uid',
        'author.image',
        'author.description',
        'banner.title',
        'banner.description',
        'banner.cta',
        'banner.cta_url'
      ],
      ref
    })

    if (!page) {
      return null
    }

    const pageSlices = page.data.body

    // Strip linked doc ids from body
    const sliceIds = pageSlices?.map((k) => k.primary.content.id) || []

    // Query prismic for nested slices via ids
    const allSliceContent = await client.getByIDs(sliceIds, {
      pageSize: 100,
      ref
    })

    // Push retrieved slice content vals back into page obj
    // eslint-disable-next-line unicorn/no-array-for-each
    pageSlices?.forEach((pageSlice) => {
      const content = allSliceContent.results.find(
        (f) => pageSlice.primary.content.id === f.id
      )

      if (!content)
        throw new Error(
          `No content found for ${pageSlice.primary.content.id}: ${pageSlice.primary.content.slug}`
        )

      // eslint-disable-next-line no-param-reassign
      pageSlice.primary.content.data = content.data
    })
    const { data, id, first_publication_date, last_publication_date, tags } =
      page
    return {
      ...data,
      id,
      first_publication_date,
      last_publication_date,
      tags
    }
  } catch (error) {
    logger(error)
    throw new Error(`Cannot fetch Prismic data for UID: ${uid}`)
  }
}

const getBySingleType = async (id, ref = null) => {
  try {
    const item = await client.getSingle(id, { ref })
    return item
  } catch (error) {
    return logger(error)
    // throw new Error('Cannot fetch Prismic data')
  }
}

const getByDocumentId = async (id, ref = null) => {
  try {
    const page = await client.getByID(id, { ref })
    return page
  } catch {
    throw new Error('Cannot fetch Prismic data')
  }
}

const getBlogNav = async (ref = null) => {
  try {
    const page = await client.getSingle('blog_nav', {
      ref
    })
    if (page?.data?.main_nav) {
      await Promise.all(
        page.data.main_nav
          .filter(({ sub_nav }) => sub_nav?.id)
          .map(async (navItem) => {
            if (navItem?.sub_nav?.id) {
              const { sub_nav } = navItem

              const item = navItem
              const result = await client.getByID(sub_nav.id, {
                fetchLinks: [
                  'recipe.title',
                  'recipe.image',
                  'recipe.image',
                  'news.title',
                  'news.image',
                  'news.image'
                ],
                ref
              })

              // Reassign to new object so we don't mutate the original data
              item.sub_nav = result
              return item
            }
            return null
          })
      )
    }
    return page
  } catch (error) {
    return logger(error)
  }
}

const getBlogHomepage = async (ref = null) => {
  try {
    const page = await client.getByID('XlPhUBIAACAAEOq6', {
      fetchLinks: [
        'recipe.title',
        'recipe.image',
        'recipe.excerpt',
        'recipe.category',
        'recipe.keywords',
        'news.title',
        'news.image',
        'news.excerpt',
        'news.category',
        'news.keywords',
        'shop_ad.headline',
        'shop_ad.subline',
        'shop_ad.cta',
        'shop_ad.background_image'
      ],
      ref
    })
    return page
  } catch {
    return null
  }
}

const getBlogArticles = async ({
  content_types,
  limit,
  page,
  orderings,
  includedIds, // Array of IDs to specifically return in results
  excludeIds, // Array of IDs to specifically exclude in results
  author,
  ref = null
}) => {
  try {
    const filters = [
      prismic.filter.any('document.type', content_types),
      includedIds ? prismic.filter.in('document.id', includedIds) : '',
      author ? prismic.filter.at(`my.${content_types}.author`, author) : ''
    ]

    // Build exclude array as Prismic doesnt allow .not to be array :(
    if (excludeIds) {
      for (const e of excludeIds) {
        filters.push(prismic.filter.not('document.id', e))
      }
    }

    const content = await client.get({
      filters,
      fetch: flatten(
        content_types.map((content_type) => [
          `${content_type}.title`,
          `${content_type}.excerpt`,
          `${content_type}.image`,
          `${content_type}.category`,
          `${content_type}.keywords`
        ])
      ),
      orderings,
      pageSize: limit || 10,
      page: page || 1,
      ref
    })

    return content
  } catch {
    throw new Error('Cannot fetch Prismic data')
  }
}

const getSearchedArticles = async ({ keyword, tags, ref = null }) => {
  const content_types = ['news', 'recipe']
  const tagQuery = prismic.filter.at('document.tags', tags)

  try {
    const options = {
      fetch: flatten(
        content_types.map((content_type) => [
          `${content_type}.title`,
          `${content_type}.excerpt`,
          `${content_type}.image`,
          `${content_type}.category`,
          `${content_type}.keywords`
        ])
      ),
      pageSize: 50,
      orderings: {
        field: 'document.last_publication_date',
        direction: 'desc'
      },
      ref
    }

    const newsTitleSearch = await client.get({
      filters: [
        prismic.filter.at('document.type', 'news'),
        tagQuery,
        prismic.filter.fulltext('my.news.title', keyword || '')
      ],
      options
    })

    const recipeTitleSearch = await client.get({
      filters: [
        prismic.filter.at('document.type', 'recipe'),
        tagQuery,
        prismic.filter.fulltext('my.recipe.title', keyword || '')
      ],
      options
    })

    const sortByIsoDateDescending = (a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.first_publication_date > b.first_publication_date
        ? -1
        : a.first_publication_date > b.first_publication_date
        ? 1
        : 0

    return {
      results: [...recipeTitleSearch.results, ...newsTitleSearch.results].sort(
        sortByIsoDateDescending
      ),
      total_results_size:
        recipeTitleSearch.total_results_size +
        newsTitleSearch.total_results_size
    }
  } catch {
    throw new Error('Cannot fetch Prismic data')
  }
}

const getBlogUids = async ({
  type,
  pageSize,
  orderings = {
    field: 'document.first_publication_date',
    direction: 'desc'
  },
  ref = null
} = {}) => {
  try {
    return await client.get({
      filters: prismic.filter.at('document.type', type),
      fetch: `${type}.uid`, // Don't fetch data, just uids
      pageSize,
      orderings,
      ref
    })
  } catch {
    throw new Error('Cannot fetch Prismic uids')
  }
}

const getArticlesByTag = async (
  content_type,
  tags,
  limit,
  page,
  ref = null
) => {
  const filters = [
    prismic.filter.at('document.type', content_type),
    prismic.filter.at('document.tags', tags),
    // filter SEO content
    prismic.filter.not('document.tags', ['hidden'])
  ]
  try {
    const content = await client.get({
      filters,
      fetch: [
        `${content_type}.title`,
        `${content_type}.image`,
        `${content_type}.category`
      ],
      /* order by first publication date to prevent re-published articles appearing at top of homepage + category pages */
      orderings: {
        field: 'document.first_publication_date',
        direction: 'desc'
      },
      pageSize: limit || 10,
      page: page || 1,
      ref
    })
    return content
  } catch {
    throw new Error('Cannot fetch Prismic data')
  }
}

const getAuthors = async (limit, page, ref = null) => {
  try {
    const content = await client.get({
      filters: [prismic.filter.at('document.type', 'author')],
      fetch: ['author.name', 'author.image'],
      orderings: {
        field: 'my.author.name'
      },
      pageSize: limit || 10,
      page: page || 1,
      ref
    })
    return content
  } catch {
    throw new Error('Cannot fetch Prismic data')
  }
}

const getSimilarArticles = async (id, content_type, limit) => {
  try {
    const page = await client.get({
      filters: [
        prismic.filter.any('document.type', content_type),
        prismic.filter.similar(id, 10)
      ],
      fetch: [
        `${content_type}.title`,
        `${content_type}.image`,
        `${content_type}.category`
      ],
      pageSize: limit
    })
    return page
  } catch {
    throw new Error('Cannot fetch Prismic data')
  }
}

const getTaggedArticles = async ({
  content_type,
  tags = [],
  excludeIds = [],
  orderings = {
    field: 'document.first_publication_date',
    direction: 'desc'
  },
  page = 1,
  pageSize = 6,
  ref = null
}) =>
  client.get({
    filters: [
      prismic.filter.at('document.type', content_type),
      prismic.filter.at('document.tags', tags),
      ...excludeIds.map((id) => prismic.filter.not('document.id', id))
    ],
    fetch: [
      `${content_type}.title`,
      `${content_type}.standfirst`,
      `${content_type}.image`,
      `${content_type}.tags`
    ],
    orderings,
    page,
    pageSize,
    ref
  })

const getCollectionContent = async (uid, ref = null) => {
  const content_types = ['news', 'recipe']

  const page = await client.getByUID('blog_collection', uid, {
    fetchLinks: flatten([
      content_types.map((content_type) => [
        `${content_type}.title`,
        `${content_type}.excerpt`,
        `${content_type}.standfirst`,
        `${content_type}.image`,
        `${content_type}.tags`,
        `${content_type}.keywords`
      ]),
      [
        'shop_ad.headline',
        'shop_ad.subline',
        'shop_ad.cta',
        'shop_ad.background_image'
      ]
    ]),
    ref
  })

  if (!page) {
    return null
  }

  const featuredArticles = page?.data?.featured_articles?.map(
    ({ article }) => article
  )

  return {
    ...page.data,
    ad_content: page?.data?.ad_content?.data || null,
    featured_articles: featuredArticles
  }
}

const getSeriesContent = async (uid, ref = null) => {
  try {
    const page = await client.getByUID('blog_series', uid, {
      fetchLinks: [
        'blog_series_hero.title',
        'blog_series_hero.description',
        'blog_series_hero.image'
      ],
      ref
    })

    // add associated collection or article data to each slice
    let sliceData = null
    if (page?.data?.body) {
      const { body } = page.data
      await Promise.all(
        body?.map(async (slice) => {
          switch (slice.slice_type) {
            case 'collection_playlist':
            case 'tiled_layout':
              sliceData = await getCollectionContent(
                slice.primary.collection.uid,
                ref
              )
              break
            case 'featured_articles':
              sliceData = await getBlogArticles({
                content_types: ['news', 'recipe'],
                includedIds: [
                  slice.primary.main_feature_article.id,
                  slice.primary.article_1.id,
                  slice.primary.article_2.id,
                  slice.primary.article_3.id,
                  slice.primary.article_4.id
                ],
                ref
              })
              break
            default:
            // etc.
          }
          const newSlice = slice
          newSlice.primary = {
            ...newSlice.primary,
            ...sliceData
          }
          return newSlice
        })
      )
    }
    if (!page) {
      return null
    }

    return { ...page.data }
  } catch (error) {
    logger(error)
    throw new Error(`Cannot fetch Prismic data for series ${uid}`)
  }
}

export {
  getArticlesByTag,
  getAuthors,
  getByDocumentId,
  getBySingleType,
  getBlogArticles,
  getBlogHomepage,
  getBlogNav,
  getCollectionContent,
  getSearchedArticles,
  getSeriesContent,
  getSimilarArticles,
  getTaggedArticles,
  getPageContent,
  getBlogUids
}

export * from '@tofu/shared/utils/link-resolver'
export * from '@tofu/shared/utils/next-link-resolver'
