import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'
import Icon from '@tofu/apps/shop/components/common/icon'
import tick from '@tofu/apps/shop/icons/tick'
import close from '@tofu/apps/shop/icons/close'
import { color, fade } from '@tofu/apps/shop/styles/variables'

const themes = {
  success: {
    bg: color.white,
    color: color.kale,
    icon: tick
  },
  error: {
    bg: color.warningLight,
    color: color.warning,
    icon: close
  }
}

const Container = styled(Flex)`
  animation: ${fade} 300ms linear;
`

const Feedback = ({ children, remove, type = 'error' }) => {
  const theme = themes[type]

  return (
    <Container
      alignItems='center'
      bg={theme.bg}
      onClick={remove}
      p={2}
      sx={{
        border: `1px solid ${theme.color}`,
        cursor: 'pointer'
      }}
    >
      <Flex
        alignItems='center'
        height='36px'
        justifyContent='center'
        sx={{
          border: `2px solid ${theme.color}`,
          borderRadius: '100%',
          lineHeight: '36px'
        }}
        width='36px'
      >
        <Icon fill={theme.color} name={theme.icon} width={20} />
      </Flex>
      <Flex
        flexDirection='column'
        fontSize='16px'
        pl={4}
        width='calc(100% - 36px)'
      >
        <Text data-test-id='feedback-heading'>
          {type === 'error' ? 'Uh oh...' : 'Hooray!'}
        </Text>
        <Text data-test-id='feedback-text'>{children}</Text>
      </Flex>
    </Container>
  )
}

Feedback.defaultProps = {
  children: null,
  type: 'error'
}

Feedback.propTypes = {
  children: PropTypes.node,
  remove: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['error', 'success'])
}

export default Feedback
