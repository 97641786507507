import { configureScope, captureException } from '@sentry/nextjs'
import { SeverityLevel } from '@sentry/types'

interface AppError extends Error {
  statusCode?: number
}

export const logger = (
  err: AppError,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ctx?: any,
  severityLevel?: SeverityLevel
) => {
  // Get the FS session url
  let fsSessionUrl: string | null = null
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof window !== 'undefined' && window.FS) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fsSessionUrl = window.FS.getCurrentSessionURL(true)
    } catch {
      // Fail silently if the session URL doesn't exist
    }
  }

  try {
    configureScope((scope) => {
      if (err.message) {
        // De-duplication currently doesn't work correctly for SSR / browser errors
        // so we force deduplication by error message if it is present
        scope.setFingerprint([err.message])
      }

      if (fsSessionUrl) {
        scope.setExtra('fsSessionURL', fsSessionUrl)
      }

      if (err.statusCode) {
        scope.setExtra('statusCode', err.statusCode)
      }

      if (ctx) {
        const { req, res, errorInfo, query, pathname } = ctx

        if (res && res.statusCode) {
          scope.setExtra('statusCode', res.statusCode)
        }

        if (typeof window !== 'undefined') {
          scope.setExtra('query', query)
          scope.setExtra('pathname', pathname)
        } else {
          scope.setExtra('url', req.url)
          scope.setExtra('method', req.method)
          scope.setExtra('headers', req.headers)
          scope.setExtra('params', req.params)
          scope.setExtra('query', req.query)
        }

        if (errorInfo) {
          for (const key of Object.keys(errorInfo))
            scope.setExtra(key, errorInfo[key])
        }
      }

      if (severityLevel) {
        scope.setLevel(severityLevel)
      }
    })
    return captureException(err)
  } catch {
    // fail silently if something goes wrong with Sentry
  }

  return null
}
